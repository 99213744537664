import React from "react";
import { isMobile } from "../../service/util";

export default () => {
  return (
    <div className="p-10 text-center text-fen flex items-center justify-center flex-wrap">
      <div className="whitespace-nowrap">
        Contact us: <a href="mailto:feedback@aitip.app">feedback@aitip.app</a>
        <br/><br/>
        AITip Technology Limited © 2024
      </div>
    </div>
  );
};
