import React, { useEffect, useState } from "react";

interface IProps {
  type: "renew" | "paid" | "agreement" | "regulate" | "privacy" | "feedback";
}

interface sectionType {
  title: string;
  contents: string[];
}

interface DocType {
  title: string;
  subTitles: string[];
  sections: sectionType[];
  footers: [];
}

const Doc: React.FC<IProps> = (props) => {
  const type = props.type;
  const [component, setComponent] = useState<DocType>({
    title: "",
    subTitles: [],
    sections: [],
    footers: [],
  });
  const loadComponent = async () => {
    // 动态导入需要加载的组件
    const dynamicComponent = await import("../components/doc/" + type);

    // 更新组件状态，将动态导入的组件设置为当前组件
    setComponent(dynamicComponent.default);
  };
  useEffect(() => {
    loadComponent().then((r) => {});
  }, []);
  return (
    <div className="p-10">
      <h1>{component.title}</h1>
      <div className="text-right">
        {component.subTitles &&
          component.subTitles.map((item, index) => {
            return <h2 key={index}>{item}</h2>;
          })}
      </div>
      <div>
        {component.sections.map((item, index) => {
          return (
            <div key={index}>
              <h3>{item.title}</h3>
              {item.contents.map((content, contentIndex) => {
                return (
                  <div key={contentIndex} dangerouslySetInnerHTML={{ __html: content }}></div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="text-right">
        {component.footers &&
          component.footers.map((item, index) => {
            return <h3 key={index}>{item}</h3>;
          })}
      </div>
    </div>
  );
};

export default Doc;
