/**
 * 判断移动端
 */
export const isMobile = () => {
  return window.navigator.userAgent.toLowerCase().match('mobile');
};

/**
 * 判断微信浏览器
 */
export const isWechat = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.includes("micromessenger");
};

/**
 * 判断浏览器是否为头部APP
 */
export const isBlackApp = () => {
  const ua = navigator.userAgent.toLowerCase();
  return (
    /micromessenger/i.test(ua) ||
    ua.includes("weibo") ||
    ua.includes("qq") ||
    ua.includes("mqqbrowser")
  );
};

/**
 * 判断平台
 */
export const isAndroid = () => {
  return navigator.userAgent.match('Android');
};
