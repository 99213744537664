import { createBrowserRouter,Route } from "react-router-dom";
import Home from "../pages/Home";
import Doc from "../pages/Doc";
import NewDoc from "../pages/NewDoc";
import React from "react";

export default createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/agreement",
    element: <Doc type="agreement" />,
  },
  {
    path: "/paid",
    element: <Doc type="paid" />,
  },
  {
    path: "/renew",
    element: <Doc type="renew" />,
  },
  {
    path: "/regulate",
    element: <Doc type="regulate" />,
  },
  {
    path: "/privacy",
    element: <Doc type="privacy" />,
  },
  {
    path: "/feedback",
    element: <Doc type="feedback" />,
  },
]);
