import React, { useEffect } from "react";
import "./home_h5.scss";
import { isAndroid } from "../../service/util";
import MyCarousel from "./MyCarousel";
import DownloadBtn from "./DownloadBtn";
import Footer from "./Footer";

const H5: React.FC = () => {
  return (
    <>
      <div className="p-2">
        <div className="h-32 bg-[url('../assets/images/home/ic_logo.webp')] bg-contain bg-no-repeat"></div>
        <div className="flex flex-col items-center pt-10">
          <div className="text-22 font-bold">AITip: Unlocking visual possibilities with AI</div>
          <div className="text-12 text-center mt-1">
            From Text to AI Beauty, Transform Real Photos to Artistic Visions
            <br />
            Unleash Your Creativity - Please Download AITip
          </div>
          <div className="m-15">
          {/* <DownloadBtn type={isAndroid() ? "android" : "ios"} /> */}
          </div>
          <MyCarousel />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default H5;
