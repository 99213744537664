import React from "react";
import MyCarousel from "./MyCarousel";
import Footer from "./Footer";
import { isAndroid } from "../../service/util";
import DownloadBtn from "./DownloadBtn";

function Home() {
  return (
    <>
      <main className="w-990 mx-auto">
        <header className="py-60">
          <div className="h-64 bg-[url('../assets/images/home/ic_logo.webp')] bg-contain bg-no-repeat"></div>
        </header>
        <div className="flex items-center">
          <MyCarousel />
          <div className="flex-1 pl-80">
            <div className="text-44  white">AITip: Unlocking visual possibilities with AI</div>
            <div className="text-20 leading-37 pt-30 tracking-widest white">
            From Text to AI Beauty, Transform Real Photos to Artistic Visions
              <br />
              Unleash Your Creativity - Please Download AITip
            </div>
            <div className="flex mt-60">
              {/* <div className="mr-30"><DownloadBtn type="ios" /></div>
              <div>
                <DownloadBtn type="android" />
              </div> */}
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}

export default Home;
