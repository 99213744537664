import { Carousel } from "antd";
import React from "react";

export default () => {
  return (
    <Carousel autoplay className="carousel">
      <div>
        <img src="https://with-us.oss-us-west-1.aliyuncs.com/aitip/home-web/1080x1920-1.png" />
      </div>
      <div>
        <img src="https://with-us.oss-us-west-1.aliyuncs.com/aitip/home-web/1080x1920-2.png" />
      </div>
      <div>
        <img src="https://with-us.oss-us-west-1.aliyuncs.com/aitip/home-web/1080x1920-3.png" />
      </div>
      <div>
        <img src="https://with-us.oss-us-west-1.aliyuncs.com/aitip/home-web/1080x1920-4.png" />
      </div>
      <div>
        <img src="https://with-us.oss-us-west-1.aliyuncs.com/aitip/home-web/1080x1920-5.png" />
      </div>
    </Carousel>
  );
};
